import React, { useEffect, useState } from 'react'
import "../css/pageCss/LoginRegisterPage.css"
import { useDispatch, useSelector } from 'react-redux';
import { register, login, checkAdminStatus } from '../redux/action/Actions';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function LoginRegisterPage() {
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const isUser = useSelector((state) => state.auth.isAuthenticated);
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const navigate = useNavigate();
    const [registerData, setRegisterData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
        city: '',
        phoneNumber: '',
    });


    useEffect(() => {
        window.scrollTo(0, 0)
        if (isAdmin) {
            navigate('/admin-home-page');
        }
        else if (isUser) {
            navigate('/');
        }
    }, [isAdmin, isUser]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterData({ ...registerData, [name]: value });
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(login(loginData));
            await dispatch(checkAdminStatus());
            setLoginData({email:"" , password:""})
        } catch (error) {
            console.error("Login error:", error);
            alert("Invalid Credentials")
            setLoginData({email:"" , password:""})
        }

    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        if (registerData.password !== registerData.confirmPassword) {
            alert("Password Not Matched")
            return;
        }
        // console.log(registerData)

        dispatch(register(registerData));
        alert("Registered Successfully")
        window.location.reload()
    };
    console.log(isAdmin, "isAdmin")
    return (

        <div className='login-register-page'>
         <Helmet>
        <title>Happy Family | Login</title>
        <meta name="description" content="Login Happy Family - a social welfare organization that offers food, shelter, education, and organizes events to encourage people to help others." />
        <meta name="keywords" content="Happy Family login page, register to happy family , registration page , login page" />
      </Helmet>
            <form action="#" method="post" className="join-form login-form" onSubmit={handleLoginSubmit}>
                <h3>Login</h3>
                <input
                    type="email"
                    className="input-field"
                    placeholder="Enter your Email*"
                    required
                    value={loginData.email}
                    onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                />
                <input
                    type="password"
                    className="input-field"
                    placeholder="Enter your Password*"
                    required
                    value={loginData.password}
                    onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}

                />
                <input type="submit" value="Login" className="download-btn" />
            </form>
            <div className="divide-line"></div>


            <form onSubmit={handleRegisterSubmit} className='join-form register-form'>
                <h3>Register</h3>
                <input
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={registerData.fullName}
                    className="input-field"
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={registerData.email}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={registerData.password}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={registerData.confirmPassword}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={registerData.city}
                    onChange={handleInputChange}
                    className="input-field"
                />
                <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={registerData.phoneNumber}
                    onChange={handleInputChange}
                    className="input-field"
                    required
                />
                <button type="submit" className="download-btn" >Register</button>
            </form>

        </div>
    )
}
