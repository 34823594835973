import React from 'react'
import "../css/componentCss/SocialMedia.css"
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
export default function SocialMedia() {
  return (
    <div className='social-media-section'>
        <h2>Connect With Us</h2>
        <div className="social-media-sites">
            <a target='_blank' href='https://api.whatsapp.com/send?phone=919620430945'><WhatsAppIcon className='social-media-icon'/></a>
            <a target='_blank' href='https://www.youtube.com/@happy-family-india'><YouTubeIcon className='social-media-icon'/></a>
            <a target='_blank' href='https://twitter.com/hpyfamily_india'><TwitterIcon className='social-media-icon'/></a>
            <a target='_blank' href='http://t.me/happy_family_india'><TelegramIcon className='social-media-icon'/></a>
        </div>
    </div>
  )
}
