import React, { useEffect, useState } from 'react'
import "../../css/adminPages/AdminUpcomingEventPage.css"
import UpcomingEventHorizontal from '../adminComponentJsx/UpcomingEventHorizontal'
import SaveAsIcon from '@mui/icons-material/SaveAs';
import tryImg from "../../images/eventImg.png"
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent, deleteEvent, fetchEvents } from '../../redux/action/Actions';

export default function AdminUpcomingEventPage() {


    const [form, setForm] = useState(new FormData());
    const events = useSelector((state) => state.upcomingEvents.events);
    const [eventScreen, setEventScreen] = useState(false);
    const dispatch = useDispatch();
    const displayEventScreen = () => {
        setEventScreen(true)
    }
    const hideEventScreen = () => {
        setEventScreen(false)
    }


    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setForm({
            ...form,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        for (const key in form) {
            formData.append(key, form[key]);
        }

        await dispatch(createEvent(formData));

        // Reset the form
        // setForm(initialFormData);
        setEventScreen(false)
    };

    const handleDelete = (id) => {
        dispatch(deleteEvent(id))
        dispatch(fetchEvents());
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchEvents());
    }, []);
    return (
        <div className='admin-upcoming-event-page'>
            <div className="admin-upcoming-event-page-top">
                <div className="admin-upcoming-event-page-top-left">
                    <h3>Want To add New Event?</h3>
                    <p>Welcome to the event creation section! Whether you're planning a conference, party, fundraiser, or any other type of event, we're here to help you get started. Please fill out the form below to provide us with the necessary details for your event.</p>
                </div>
                <div className="admin-upcoming-event-page-top-right" onClick={displayEventScreen}>
                    <SaveAsIcon className='create-icon' />
                    <p>Create Event</p>
                </div>
            </div>
            {/* eventName="Event Name XYZ" eventDate="23/03/2003" eventImage={tryImg} */}
            <div className="admin-upcoming-event-page-bottom">
                {
                    events.length > 0 && events.map((item) => {
                        return <UpcomingEventHorizontal key={item.id} {...item} handleDelete={handleDelete} />
                    })
                }
            </div>
            {eventScreen && (
                <div className="create-event-screen">
                    <form className="create-event-form" onSubmit={handleSubmit} action="/upload" >
                        <CloseIcon className='close-icon' onClick={hideEventScreen} />
                        <div className="row">
                            <div className="upload-image">
                                <p>Click Here To Upload Image</p>
                                <input
                                    type="file"
                                    name="thumbnail"
                                    accept=".jpg, .jpeg, .png"
                                    id="uploadBtn"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="name-and-date-box">
                                <input
                                    type="text"
                                    name="eventName"
                                    placeholder='Enter Event Name'
                                    className='input-field'
                                    value={form.eventName}
                                    onChange={handleInputChange}
                                />
                                <input
                                    type="date"
                                    name="eventDate"
                                    placeholder="dd-mm-yyyy"
                                    className="input-field"
                                    value={form.eventDate}
                                    onChange={handleInputChange}
                                    pattern="\d{2}/\d{2}/\d{4}"
                                />
                                <input
                                    type="text"
                                    name="googleFormLink"
                                    className="input-field"
                                    placeholder='Enter Google Form Link'
                                    value={form.googleFormLink}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <textarea
                                name="detailedDescription"
                                placeholder='Enter Detailed Description (100 words)'
                                value={form.detailedDescription}
                                onChange={handleInputChange}
                                className='description-box'
                            ></textarea>
                            <textarea
                                name="eventSummary"
                                className='summary'
                                placeholder='Enter Event Summary (20-30 words)'
                                value={form.eventSummary}
                                onChange={handleInputChange}
                            ></textarea>
                        </div>
                        <input type="submit" value="Create Event" className='download-btn' />
                    </form>
                </div>
            )}
        </div>
    )
}
