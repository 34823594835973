import React from 'react'
import "../css/componentCss/TestimonialCard.css"
import frame from "../images/frame.png"
import user from "../images/eventImg.png"
export default function TestimonialCard(props) {
  return (
    <div className='testimonial-card'>
        <img src={frame} alt="" className='frame'/>
        <img src={user} alt="" className='user-img'/>
        <h5>{props.name}</h5>
        <p>{props.feedback}</p>
    </div>
  )
}
