import React, { useEffect } from 'react'
import "../../css/adminPages/AdminHomePage.css"
import RegisteredUserListCard from '../adminComponentJsx/RegisteredUserListCard'
import EventListCard from '../adminComponentJsx/EventListCard'
import testImg from "../../images/eventImg.png"
import UserQueryCard from '../adminComponentJsx/UserQueryCard'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMessage, fetchEvents, fetchMessage, fetchPrevEvents, fetchUser } from '../../redux/action/Actions'
export default function AdminHomePage() {
    const preevevents = useSelector((state) => state.upcomingEvents.prevEvents);
    const events = useSelector((state) => state.upcomingEvents.events);
    const userList = useSelector((state) => state.upcomingEvents.userList);
    const message = useSelector((state) => state.upcomingEvents.messages);
    const dispatch = useDispatch();

    const handleDelete = (id) => {
        dispatch(deleteMessage(id))
        dispatch(fetchMessage())

    }
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchPrevEvents());
        dispatch(fetchEvents());
        dispatch(fetchUser());
        dispatch(fetchMessage());
    }, [])

    console.log('Fetching message', message)
    return (
        <div className='admin-home-page'>
            <div className="admin-home-page-first-row common-flex-section">
                <div className="admin-home-page-first-row-card">
                    <h2>{userList.length}</h2>
                    <p>Total Number of Registered Users</p>
                </div>
                <div className="admin-home-page-first-row-card">
                    <h2>{events.length}</h2>
                    <p>Listed Upcoming Events</p>
                </div>
                <div className="admin-home-page-first-row-card">
                    <h2>{preevevents.length}</h2>
                    <p>Listed Previous Events</p>
                </div>
            </div>

            <div className="admin-home-page-second-row-left-column">
                <h3>Registered Users</h3>
                <div className="verticle-scrollable-section">
                    {
                        userList && userList.map((item, index) => {
                            return <RegisteredUserListCard {...item} key={index} />
                        })
                    }


                </div>
            </div>


            <div className="admin-home-page-third-row">
                <h3>User Query</h3>
                <div className="verticle-scrollable-section">
                    {
                        message.length > 0 && message.map((message, index) => {
                            return <UserQueryCard key={index} {...message} handleDelete={handleDelete} />
                        })
                    }


                </div>
            </div>


        </div>
    )
}
