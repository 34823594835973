import React, { useEffect } from 'react'
import "../css/pageCss/VolunteerGuidelinePage.css"
import volunteer_hand from "../images/volunteer landing section hands.png"
import loveImg from "../images/love-img.png"
import VolunteerPdfSection from '../components/VolunteerPdfSection'
import UpcomingEventsSection from '../components/UpcomingEventsSection';
import TestimonialSection from '../components/TestimonialSection';
import responsibility1 from "../images/volunteer-work-1.png"
import responsibility2 from "../images/volunteer-work-2.png"
import responsibility3 from "../images/volunteer-work-3.png"
import responsibility4 from "../images/volunteer-work-4.png"
import { Helmet } from 'react-helmet';



export default function VolunteerGuidelinePage() {
    useEffect(() => {
        window.scrollTo(0,0)
    },[])
  return (
    <>
     <Helmet>
        <title>Happy Family | Volunteer Guidelines</title>
        <meta name="description" content="Become a volunteer at Happy Family - a social welfare organization that offers food, shelter, education, and organizes events to encourage people to help others." />
        <meta name="keywords" content="Happy Family volunteer guidelines, become volunteer in happy family , volunteer page of happy family , volunteer page" />
      </Helmet>
    <div className='volunteer-guideline-landing-section'>
        <div className="volunteer-guideline-landing-section-left">
            <h1>VOLUNTEER <br/>GUIDELINES</h1>
            <p>At Happy Family, the dedication of our volunteers is a cornerstone of our mission. To ensure a positive and effective experience for both volunteers and beneficiaries, we have a few important guidelines</p>
        </div>
        <div className="volunteer-guideline-landing-section-right">
            <img src={volunteer_hand} alt="" />
        </div>
    </div>

    <div className="volunteer-guideline-responsibility-section">
        <div className="responsibility-section-top">
            <h2>What will be your responsibilites ?</h2>
        </div>
        <div className="responsibility-section-between">
            <div className="responsibility-section-between-left">
                <div className="responsibility-section-between-left-item">
                        <img src={responsibility1} alt="" />
                        <h5>Responsibility Name</h5>
                </div>
                <div className="responsibility-section-between-left-item">
                        <img src={responsibility3} alt="" />
                        <h5>Responsibility Name</h5>
                </div>
                <div className="responsibility-section-between-left-item">
                        <img src={responsibility2} alt="" />
                        <h5>Responsibility Name</h5>
                </div>
                <div className="responsibility-section-between-left-item">
                        <img src={responsibility4} alt="" />
                        <h5>Responsibility Name</h5>
                </div>
            </div>
            <div className="responsibility-section-between-right">
                <p>As a volunteer with Happy Family, your responsibilities are vital to our mission's success. Your main duties include dedicating your time and energy to provide support, care, and assistance to those in need. This includes maintaining a respectful and compassionate demeanor in all interactions, following safety guidelines, and ensuring the privacy and confidentiality of the individuals we serve</p>
                <p>By fulfilling these responsibilities, you become an integral part of creating a brighter, happier world for those less fortunate and help us continue to make a positive impact.</p>
            </div>
        </div>
        <div className="responsibility-section-bottom">
            <p>This includes maintaining a respectful and compassionate demeanor in all interactions, following safety guidelines, and ensuring the privacy and confidentiality of the individuals we serve. Your commitment to the agreed-upon schedule and open communication with our team are equally important</p>
        </div>
    </div>

    <VolunteerPdfSection/>

    <div className="volunteer-guideline-love-section">
        <h2>Be the reason for someone’s smile</h2>
        <img src={loveImg} alt="" />
    </div>

    <UpcomingEventsSection/>

    <TestimonialSection/>

    </>
  )
}
