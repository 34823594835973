import React from 'react'
import "../css/componentCss/CommonCard.css";
export default function CommonCard(props) {
  return (
    <div className='common-card'>
        <img src={props.image} alt="" />
        <h4>{props.title}</h4>
        <p>{props.description}</p>
    </div>
  )
}
