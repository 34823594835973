
export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const POST_EVENT_REQUEST = 'POST_EVENT_REQUEST';
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS';
export const POST_EVENT_FAILURE = 'POST_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const FETCH_PREVEVENTS_SUCCESS = "FETCH_PREVEVENTS_SUCCESS";
export const DELETE_PREVEVENT_SUCCESS = 'DELETE_PREVEVENT_SUCCESS';

export const EDIT_EVENTS_REQUEST = 'EDIT_EVENTS_REQUEST';
export const EDIT_EVENTS_SUCCESS = 'EDIT_EVENTS_SUCCESS';
export const EDIT_EVENTS_FAILURE = 'EDIT_EVENTS_FAILURE';

export const USER_QUERY_SUCCESS = 'USER_QUERY_SUCCESS';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';

export const SEND_MESSAGE = "SEND_MESSAGE";
export const FETCH_MESSAGE = "FETCH_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

// Action creators for creating and deleting events
export const postEventRequest = () => ({
    type: POST_EVENT_REQUEST,
});

export const postEventSuccess = (event) => ({
    type: POST_EVENT_SUCCESS,
    payload: event,
});

export const postEventFailure = (error) => ({
    type: POST_EVENT_FAILURE,
    payload: error,
});

export const deleteEventRequest = () => ({
    type: DELETE_EVENT_REQUEST,
});

export const deleteEventSuccess = (eventId) => ({
    type: DELETE_EVENT_SUCCESS,
    payload: eventId,
});

export const deletePrevEventSuccess = (eventId) => ({
    type: DELETE_PREVEVENT_SUCCESS,
    payload: eventId,
});


export const deleteEventFailure = (error) => ({
    type: DELETE_EVENT_FAILURE,
    payload: error,
});

export const fetchEventsRequest = () => ({
    type: FETCH_EVENTS_REQUEST,
});

export const fetchEventsSuccess = (events) => ({
    type: FETCH_EVENTS_SUCCESS,
    payload: events,
});

export const fetchEventsFailure = (error) => ({
    type: FETCH_EVENTS_FAILURE,
    payload: error,
});

export const fetchPrevEvent = (events) => ({
    type: FETCH_PREVEVENTS_SUCCESS,
    payload: events
});

export const fetchEditEventsSuccess = (events) => ({
    type: EDIT_EVENTS_SUCCESS,
    payload: events
});

export const fetchUserQuery = (data) => ({
    type: USER_QUERY_SUCCESS,
    payload: data
});

export const fetchUserList = (data) => ({
    type: USER_LIST_SUCCESS,
    payload: data
});

export const login = (userData) => ({
    type: 'LOGIN',
    payload: userData,
});

export const fetchMess = (data) => ({
    type: FETCH_MESSAGE,
    payload: data
});

export const deleteMess = (id) => ({
    type: DELETE_MESSAGE,
    payload: id
});

export const loginUser = (userData, token) => ({
    type: 'LOGIN_USER',
    payload: userData,
    token,
});

export const logoutUser = () => ({
    type: 'LOGOUT_USER',
});

export const loginAdmin = (adminData, token) => ({
    type: 'LOGIN_ADMIN',
    payload: adminData,
    token,
});

export const logoutAdmin = () => ({
    type: 'LOGOUT_ADMIN',
});
