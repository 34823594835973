import React, { useState } from 'react'
import "../../css/adminComponents/PreviousEventMainCard.css"
import { deleteEvent } from '../../redux/action/Actions'
import { useDispatch } from 'react-redux';
// require("dotenv").config();
export default function PreviousEventMainCard(props) {

  const [deleteScreen, setDeleteScreen] = useState(false)
  const dispatch = useDispatch();
  const showDeleteScreen = () => {
    setDeleteScreen(true)
  }
  const hideDeleteScreen = () => {
    setDeleteScreen(false)
  }

  const deletehandle = (id) => {
    props.handleDelete(id)
    hideDeleteScreen()
  }


  return (
    <>
      <div className='previous-event-main-card'>
        <div className="previous-event-main-card-top">
          <div className="previous-event-main-card-top-upper-part">
            <div className="previous-event-main-card-top-upper-part-left">
              <img src={props.thumbnail} alt={props.thumbnail} />
            </div>
            <div className="previous-event-main-card-top-upper-part-right">
              <h5>{props.eventName}</h5>
              <h6>{props.eventDate}</h6>
            </div>
          </div>
          <div className="previous-event-main-card-top-bottom-part">
            <p>{props.eventDescription}</p>
          </div>
        </div>
        <div className="previous-event-main-card-bottom">
          <button className='btn-a download-btn' onClick={showDeleteScreen}>Delete Event</button>
        </div>
      </div>
      {deleteScreen && <div className="delete-screen">
        <div className="delete-msg-box">
          <h5>Do you want to delete ?</h5>
          <div className="common-flex-section">
            <div className="btn-a confirm-btn" onClick={() => deletehandle(props._id)}>Confirm</div>
            <div className="btn-a cancel-btn" onClick={hideDeleteScreen}>Cancel</div>
          </div>
        </div>
      </div>}
    </>
  )
}
