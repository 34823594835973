import React, { useEffect } from 'react'
import "../css/componentCss/PreviousEventSection.css"
import PreviousEventCard from './PreviousEventCard'
import { fetchPrevEvents } from '../redux/action/Actions';
import { useDispatch, useSelector } from 'react-redux';
export default function PreviousEventSection() {
    const preevevents = useSelector((state) => state.upcomingEvents.prevEvents);
    const dispatch = useDispatch();




    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchPrevEvents());
    }, []);



    useEffect(() => {
        dispatch(fetchPrevEvents());
    }, []);
    return (
        <div className='previous-event-section'>
            <div className="previous-event-section-top">
                <h2><span>PREVIOUS</span> EVENTS</h2>
                <p>Take a look back at the impactful events that have shaped our journey. These gatherings have brought our community together to support our mission.</p>
            </div>
            <div className="previous-event-section-bottom">
                {
                    preevevents.length > 0 && preevevents.map((elem, index) => (
                        <PreviousEventCard key={index} {...elem} />
                    )
                    )}
            </div>
        </div>
    )
}
