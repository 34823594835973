

import Axios from '../../api';
import {
    postEventRequest,
    postEventSuccess,
    postEventFailure,
    deleteEventRequest,
    deleteEventSuccess,
    deleteEventFailure,
    fetchEventsRequest,
    fetchEventsSuccess,
    fetchEventsFailure,
    fetchPrevEvent,
    deletePrevEventSuccess,
    fetchEditEventsSuccess,
    fetchUserList,
    fetchUserQuery,
    SEND_MESSAGE,
    fetchMess,
    deleteMess,
    loginAdmin,
    loginUser,
    logoutUser,
    logoutAdmin,
} from './eventActions';

export const createEvent = (eventData) => async (dispatch) => {
    try {
        dispatch(postEventRequest());
        const response = await Axios.post('api/admin/upcoming-events', eventData);
        const createdEvent = response.data;

        dispatch(postEventSuccess(createdEvent));
    } catch (error) {
        dispatch(postEventFailure(error.message));
    }
};

export const deleteEvent = (eventId) => async (dispatch) => {
    try {
        dispatch(deleteEventRequest());
        const response = await Axios.delete(`api/admin/events/${eventId}`);

        dispatch(deleteEventSuccess(eventId));
    } catch (error) {
        dispatch(deleteEventFailure(error.message));
    }
};

export const deletePrevEvent = (eventId) => async (dispatch) => {
    try {
        dispatch(deleteEventRequest());
        const response = await Axios.delete(`api/admin/events/${eventId}`);

        dispatch(deletePrevEventSuccess(eventId));
    } catch (error) {
        dispatch(deleteEventFailure(error.message));
    }
};

export const fetchEvents = () => async (dispatch) => {
    try {
        dispatch(fetchEventsRequest());
        const response = await Axios.get('api/admin/events');
        // const updatedEvents = response.data.map((event) => ({
        //     ...event,
        //     eventDate: formatDate(event.eventDate),
        // }));

        dispatch(fetchEventsSuccess(response.data));
    } catch (error) {
        dispatch(fetchEventsFailure(error.message));
    }
};

export const editEvents = (eventData) => async (dispatch) => {
    try {
        const response = await Axios.post(`api/admin/edit-events/${eventData._id}`, eventData);
        // const updatedEvents = [eventData].map((event) => ({
        //     ...event,
        //     eventDate: formatDate(event.eventDate),
        // }));
        // console.log("updatedEvents", updatedEvents);
        dispatch(fetchEditEventsSuccess(response.data));
    } catch (error) {
        dispatch(fetchEventsFailure(error.message));
    }
};

export const fetchPrevEvents = () => async (dispatch) => {
    try {
        dispatch(fetchEventsRequest());
        const response = await Axios.get('api/admin/prev-events');
        // const updatedEvents = response.data.map((event) => ({
        //     ...event,
        //     eventDate: formatDate(event.eventDate),
        // }));
        dispatch(fetchPrevEvent(response.data));
    } catch (error) {
        dispatch(fetchEventsFailure(error.message));
    }
};

export const fetchUser = () => async (dispatch) => {
    try {
        const response = await Axios.get('api/admin/getuserlist');

        dispatch(fetchUserList(response.data));
    } catch (error) {
        dispatch(fetchEventsFailure(error.message));
    }
};

export const fetchQuery = () => async (dispatch) => {
    try {
        const response = await Axios.get('api/admin/getuserquery');

        dispatch(fetchUserQuery(response.data));
    } catch (error) {
        dispatch(fetchEventsFailure(error.message));
    }
};

export const login = (data) => async (dispatch) => {
    const response = await Axios.post('api/auth/login', data);
    let { token, user } = response.data;
    if (user === "admin") {
        localStorage.setItem('admin_Token', token);
        dispatch(loginAdmin(data, token));
    } else {
        localStorage.setItem('user_Token', token);
        dispatch(loginUser(data, token));
    }

};

export const register = (user) => async (dispatch) => {
    const response = await Axios.post('api/auth/register', user);
    return response
};

export const Logout = () => (dispatch) => {
    localStorage.removeItem('user_Token');
    localStorage.removeItem('admin_Token');
    dispatch(logoutUser());
    dispatch(logoutAdmin());
};

export const sendMessage = (messageData) => async (dispatch) => {
    const response = await Axios.post('api/auth/feedback', messageData);
    dispatch({
        type: SEND_MESSAGE,
        payload: messageData
    });
};

export const fetchMessage = () => async (dispatch) => {
    try {
        const response = await Axios.get('api/admin/getfeedback');
        dispatch(fetchMess(response.data));
    } catch (error) {
        dispatch(fetchEventsFailure(error.message));
    }
};

export const deleteMessage = (id) => async (dispatch) => {
    try {
        const response = await Axios.delete(`api/admin/feedback/${id}`);
        dispatch(deleteMess(response.data));
    } catch (error) {
        dispatch(fetchEventsFailure(error.message));
    }
};

export const checkAdminStatus = () => (dispatch) => {
    const adminToken = localStorage.getItem('admin_Token');

    if (adminToken) {
        dispatch({ type: 'SET_IS_ADMIN', isAdmin: true });
    } else {
        dispatch({ type: 'SET_IS_ADMIN', isAdmin: false });
    }
};



// const handleUserLogin = (userData, userToken) => {
//     dispatch(loginUser(userData, userToken));
// }

// const handleUserLogout = () => {
//     dispatch(logoutUser());
// }

// const handleAdminLogin = (adminData, adminToken) => {
//     dispatch(loginAdmin(adminData, adminToken));
// }

// const handleAdminLogout = () => {
//     dispatch(logoutAdmin());
// }
