import React, { useState } from 'react'
import "../../css/adminComponents/AdminNavbar.css"
import logo from "../../images/logo.png"
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { Logout } from '../../redux/action/Actions';
export default function AdminNavbar() {
  const [slidingNavbar, setSlidingNavbar] = useState(false);
  const dispatch = useDispatch();
  const enableSlidingBar = () => {
    setSlidingNavbar(true)
  }
  const disableSlidingBar = () => {
    setSlidingNavbar(false)
  }
  const [deleteScreen, setDeleteScreen] = useState(false)
  const showDeleteScreen = () => {
    setDeleteScreen(true)
  }
  const hideDeleteScreen = () => {
    setDeleteScreen(false)
  }

  const handleLogout = () => {
    dispatch(Logout())
    hideDeleteScreen();
    window.location.reload()
  }
  return (
    <>
      <div className='admin-navbar'>
        <div className="admin-navbar-left">
          <img src={logo} alt="" />
          <h4>Welcome Mr. Naveen Bathina</h4>
        </div>
        <div className="admin-navbar-right" onClick={enableSlidingBar}>
          <MenuIcon />
        </div>

        {slidingNavbar && <div className="sliding-admin-navbar">
          <Link to="/admin-home-page" onClick={disableSlidingBar}><h4>Dashboard</h4></Link>
          <Link to="/admin-upcoming-event-page" onClick={disableSlidingBar}><h4>Upcoming Event</h4></Link>
          <Link to="/admin-previous-event-page" onClick={disableSlidingBar}><h4>Previous Event</h4></Link>
          <button className='btn-a' onClick={showDeleteScreen}>Logout</button>
          <CloseIcon className='close-icon' onClick={disableSlidingBar} />
        </div>}
      </div>
      {deleteScreen && <div className="delete-screen">
        <div className="delete-msg-box">
          <h4>WANT TO LOGOUT ?</h4>
          <div className="common-flex-section">
            <div className="btn-a confirm-btn" onClick={handleLogout}>Confirm</div>
            <div className="btn-a cancel-btn" onClick={hideDeleteScreen}>Cancel</div>
          </div>
        </div>
      </div>}
    </>
  )
}
