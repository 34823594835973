
import {
    FETCH_EVENTS_REQUEST,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE,
    POST_EVENT_REQUEST,
    POST_EVENT_SUCCESS,
    POST_EVENT_FAILURE,
    DELETE_EVENT_REQUEST,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAILURE,
    FETCH_PREVEVENTS_SUCCESS,
    DELETE_PREVEVENT_SUCCESS,
    EDIT_EVENTS_SUCCESS,
    USER_LIST_SUCCESS,
    USER_QUERY_SUCCESS,
    SEND_MESSAGE,
    FETCH_MESSAGE,
    DELETE_MESSAGE,
} from '../action/eventActions';

const initialState = {
    events: [],
    loading: false,
    error: null,
    prevEvents: [],
    userList: [],
    queryList: [],
    messages: [],
};

const eventReducer = (state = initialState, action) => {

    console.log(action)
    switch (action.type) {
        case FETCH_EVENTS_REQUEST:
        case POST_EVENT_REQUEST:
        case DELETE_EVENT_REQUEST:
            return { ...state, loading: true, error: null };

        case FETCH_EVENTS_SUCCESS:
            return { ...state, loading: false, events: action.payload, error: null };

        case FETCH_PREVEVENTS_SUCCESS:
            return { ...state, loading: false, prevEvents: action.payload, error: null };

        case USER_QUERY_SUCCESS:
            return { ...state, loading: false, queryList: action.payload, error: null };

        case USER_LIST_SUCCESS:
            return { ...state, loading: false, userList: action.payload, error: null };

        case POST_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: [...state.events, action.payload],
                error: null,
            };

        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: state.events.filter((event) => event._id !== action.payload),
                error: null,
            };

        case EDIT_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                events: state.events.map((event) =>
                    event._id === action.payload._id ? action.payload : event
                ),
                error: null,
            };

        case SEND_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload],
            };
        case FETCH_MESSAGE:
            return {
                ...state,
                messages: action.payload,
            };

        case DELETE_MESSAGE:
            return {
                ...state,
                messages: state.messages.filter((event) => event._id !== action.payload),
                error: null,
            };

        case DELETE_PREVEVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                prevEvents: state.prevEvents.filter((event) => event._id !== action.payload),
                error: null,
            };

        case FETCH_EVENTS_FAILURE:
        case POST_EVENT_FAILURE:
        case DELETE_EVENT_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default eventReducer;
