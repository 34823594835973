import React from 'react'
import "../../css/adminComponents/RegisteredUserListCard.css"
export default function RegisteredUserListCard(props) {
  return (
    <div className='registered-user-list-card'>
      <div className="registered-user-list-card-left">
        <h4>{props.fullName}</h4>
        <h4>{props.city}</h4>
      </div>
      <div className="registered-user-list-card-right">
        <p>{props.phoneNumber}</p>
        <p>{props.email}</p>
      </div>
    </div>
  )
}
