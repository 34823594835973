import React from 'react'
import "../css/componentCss/PartnerSection.css"
import company1 from "../images/one-brain-logo.png"
import company2 from "../images/c logo 2.png"
import company3 from "../images/c logo 3.png"
import company4 from "../images/c logo 4.png"
export default function PartnerSection() {
  return (
    <div className="partner-section">
        <div className="partner-section-right">
            <h1>Our Partners</h1>
            <p>At Happy Family, we're grateful for the invaluable support and collaboration of our esteemed partners. Together, we amplify our mission to provide shelter, education, and nourishment to those in need. These partnerships enable us to reach more individuals and create a greater impact, forging a path to a happier, more equitable world. Our partners include philanthropic organizations, local businesses, schools, and individuals who share our vision of making a difference in the lives of the less fortunate. We are proud to collaborate with these entities, and their contributions make our work possible.</p>
           
        </div>
        <div className="partner-section-left">
            <div className="partner-section-left-item">
                <img src={company1} alt="" style={{filter:"invert(100%)"}} />
                <h5>One Brain Innovation</h5>
            </div>
            {/* <div className="partner-section-left-item">
                <img src={company2} alt="" />
                <h5>Company Name</h5>
            </div>
            <div className="partner-section-left-item">
                <img src={company3} alt="" />
                <h5>Company Name</h5>
            </div>
            <div className="partner-section-left-item">
                <img src={company4} alt="" />
                <h5>Company Name</h5>
            </div> */}
        </div>
    </div>
  )
}
