import React from 'react'
import "../css/componentCss/VolunteerPdfSection.css"
import pdf from "../pdf/Happy.pdf"
import pdfIcon from "../images/pdf icon.png"
export default function VolunteerPdfSection() {
  return (
    <div className='volunteer-pdf-section'>
      <div className="volunteer-pdf-section-left">
        <img src={pdfIcon} alt="" />
        <h5>Detailed PDF of Volunteer Guideline</h5>
        <a href="#" target='_blank' download={pdf} className="download-btn">Donwlod Guidelines</a>
      </div>
      <div className="volunteer-pdf-section-right">
        <h2>Know More About Volunteer Guidelines</h2>
        <p>you can access and download our comprehensive Volunteer Guidelines in PDF format. These guidelines outline the responsibilities, expectations, and essential information for our dedicated volunteers. Please take a moment to review this document to ensure a positive and meaningful experience as you contribute to our mission. Your commitment to these guidelines is instrumental in making a lasting impact on the lives of those in need. Thank you for being part of our team and sharing our vision of creating a brighter, happier world.</p>
      </div>
    </div>
  )
}
