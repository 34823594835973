import React, { useEffect, useState } from 'react'
import "../css/pageCss/ContactPage.css"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../redux/action/Actions';
import { Helmet } from 'react-helmet';

export default function ContactPage() {

    const dispatch = useDispatch();
    const [messageData, setMessageData] = useState({
        name: '',
        email: '',
        querydetails: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMessageData({ ...messageData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(messageData);
        dispatch(sendMessage(messageData));
        setMessageData({ name: '', email: '', querydetails: '' });
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div className='contact-page'>
            <Helmet>
                <title>Happy Family | Contact Us</title>
                <meta name="description" content="Contact Happy Family - a social welfare organization that offers food, shelter, education, and organizes events to encourage people to help others." />
                <meta name="keywords" content="Contact Happy Family, contact page , connect with us" />
            </Helmet>
            <div className="contact-page-left">
                <form action="#" method="post" className="contact-form" onSubmit={handleSubmit}>
                    <h4>Send Message</h4>
                    <input
                        type="text"
                        name="name"
                        value={messageData.name}
                        onChange={handleInputChange}
                        className="input-field"
                        placeholder="Your Name"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        value={messageData.email}
                        onChange={handleInputChange}
                        className="input-field"
                        placeholder="Your Email"
                        required
                    />
                    <div className="text-box">
                        <p>Your Message</p>
                        <textarea
                            name="querydetails"
                            value={messageData.querydetails}
                            onChange={handleInputChange}
                            required
                        ></textarea>
                    </div>
                    <input type="submit" value="Send Message" className="download-btn" />
                </form>
            </div>
            <div className="contact-page-right">
                <div className="contact-page-right-row">
                    <div className="contact-page-right-row-left">
                        <LocationOnIcon className='contact-page-right-row-left-icon' />
                    </div>
                    <div className="contact-page-right-row-right">
                        <h5>Office Address</h5>
                        <p>Narepally, Hyderabad, India - 500088</p>
                    </div>
                </div>
                <div className="contact-page-right-row">
                    <div className="contact-page-right-row-left">
                        <CallIcon className='contact-page-right-row-left-icon' />
                    </div>
                    <div className="contact-page-right-row-right">
                        <h5>Phone Number</h5>
                        <p>+91 953 807 6717</p>
                    </div>
                </div>
                <div className="contact-page-right-row">
                    <div className="contact-page-right-row-left">
                        <AlternateEmailIcon className='contact-page-right-row-left-icon' />
                    </div>
                    <div className="contact-page-right-row-right">
                        <h5>Official Mail</h5>
                        <p>santhoshakutumbam@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
