import React from 'react'
import "../css/componentCss/PreviousEventCard.css"
import poster from "../images/eventImg.png"
export default function PreviousEventCard(props) {
  return (
    <div className='previous-event-card'>
      <img src={props.thumbnail} alt={props.thumbnail} />
      <h3>{props.eventName}</h3>
      <p>{props.eventDescription}</p>
    </div>
  )
}
