import './App.css';
import {Routes, Route, HashRouter } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Navbar from './pages/Navbar';
import Footer from './pages/Footer';
import VolunteerGuidelinePage from './pages/VolunteerGuidelinePage';
import EventPage from './pages/EventPage';
import LoginRegisterPage from "./pages/LoginRegisterPage"
import ContactPage from './pages/ContactPage';
import AdminHomePage from './admin/adminPageJsx/AdminHomePage';
import AdminNavbar from './admin/adminComponentJsx/AdminNavbar';
import { useEffect, useState } from 'react';
import AdminPreviousEventPage from './admin/adminPageJsx/AdminPreviousEventPage';
import AdminUpcomingEventPage from './admin/adminPageJsx/AdminUpcomingEventPage';
import AdminFooter from './admin/adminComponentJsx/AdminFooter';
import CheckAuth from './CheckAuth/CheckAuth';
import { useDispatch, useSelector } from 'react-redux';
import { checkAdminStatus } from './redux/action/Actions';
function App() {
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAdminStatus());
    console.log(`CheckAuth`, isAdmin);
  }, [dispatch]);
  return (
    <HashRouter>
      {isAdmin ? <AdminNavbar /> : <Navbar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Happy-Family" element={<LandingPage />} />
        <Route path="/events" element={<EventPage />} />
        <Route path="/volunteer-guideline" element={<VolunteerGuidelinePage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/login-register" element={<LoginRegisterPage />} />
        <Route
          path="/admin-home-page"
          element={
            <CheckAuth>
              <AdminHomePage />
            </CheckAuth>
          }
        />
        <Route
          path="/admin-previous-event-page"
          element={
            <CheckAuth>
              <AdminPreviousEventPage />
            </CheckAuth>
          }
        />
        <Route
          path="/admin-upcoming-event-page"
          element={
            <CheckAuth>
              <AdminUpcomingEventPage />
            </CheckAuth>
          }
        />
      </Routes>

      {isAdmin ? <AdminFooter /> : <Footer />}
    </HashRouter>
  );
}

export default App;
