import React, { useEffect, useState } from 'react'
import "../css/componentCss/UpcomingEventsSection.css"
import EventListCard from './EventListCard'
import detailedEventImage from "../images/home page landing section bg.png"
import { fetchEvents } from '../redux/action/Actions'
import { useDispatch, useSelector } from 'react-redux'
export default function UpcomingEventsSection() {
    const events = useSelector((state) => state.upcomingEvents.events);
    const dispatch = useDispatch();
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventDate, seteventDate] = useState("");
    const [link, setLink] = useState("");
    function handleEvent(e) {
        let eventNameValue;
        let eventDescriptionValue;
        let Date;
        let googlelink;
        if (e.target.className == "event-list-card") {
            eventNameValue = e.target.querySelector(".event-summary").querySelector("h5").innerHTML;
            eventDescriptionValue = e.target.querySelector(".event-summary").querySelector("p").innerHTML;
            Date = e.target.querySelector(".event-summary").querySelector("h6").innerHTML;
            googlelink = e.target.querySelector(".event-summary").querySelector("text").innerHTML;
            setEventName(eventNameValue);
            setEventDescription(eventDescriptionValue);
            seteventDate(Date);
            setLink(googlelink)
        }
        else if (e.target.tagName == "P" || e.target.tagName == "H5" || e.target.tagName == "IMG") {
            console.log("working");
        }
    }

    function handleEvent(eventData) {
        setSelectedEvent(eventData);
    }
    useEffect(() => {
        if (events.length > 0 && !selectedEvent) {
            setSelectedEvent(events[0]);
        }
    }, [events, selectedEvent]);
    useEffect(() => {
        dispatch(fetchEvents());
    }, []);

    return (
        <div className='upcoming-events-section'>
            <h2>Upcoming Events</h2>
            <p>Discover what's on the horizon at Happy Family! Stay tuned for exciting fundraisers, community initiatives, and opportunities to make a difference. Join us in our mission!</p>
            <p>oin us in our mission, and be part of the positive change ahead!</p>
            <div className="events-section common-flex-section">
                <div className="event-list-box">
                    {events.map((elem, index) => (
                        <div
                            key={elem.id}
                            className="event-list-card"
                            onClick={() => handleEvent(elem)}
                        >
                            <EventListCard {...elem} />
                        </div>
                    ))}
                </div>

                <div className="particular-event-box">

                    {selectedEvent ? (
                        <>
                            <img src={selectedEvent.thumbnail} alt={selectedEvent.thumbnail} />
                            <h4>{selectedEvent.eventName}</h4>
                            <p>{selectedEvent.detailedDescription}</p>
                            <h5>{selectedEvent.eventDate}</h5>
                            <a
                                href={selectedEvent.googleFormLink}
                                className="btn-a" target="_blank">
                                Become A Volunteer
                            </a>
                        </>
                    ) : (
                        <p>Select an event to see details.</p>
                    )}
                </div>

            </div>
        </div>
    )
}
