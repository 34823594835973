import React from 'react'
import "../css/pageCss/Footer.css"
import logo from "../images/logo.png"
import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <div className='footer'>
        <div className="footer-top">
            <div className="column" id="column1">
                <img src={logo} alt="" />
                <h3>Happy Family</h3>
            </div>
            <div className="column" id="column2">
                <Link to="/Happy-Family">Home</Link>
                <Link to="/events">Events</Link>
                <Link to="/volunteer-guideline">Volunteer Guidelines</Link>
                <Link to="/contact-us">Contact Us</Link>
            </div>
            <div className="column" id="column3">
                <p>Happy Family is a dedicated non-profit organization committed to improving the lives of the less fortunate. We provide shelter, education, food, and support to those in need, with a mission to create a brighter, happier world. Through unwavering dedication and a compassionate team of volunteers, we've made a profound impact on countless lives, fostering a sense of hope and belonging in our communities. Together, we strive to make a meaningful difference and uplift those who need it most. Join us in our journey of creating positive change and spreading happiness.</p>
                <p>Happy Family Organization stands as a beacon of compassion and a symbol of community-driven change. With your support, we're continually expanding our efforts to bring joy and transformation to the lives of the underserved. Join us in our mission to make the world a better place.</p>
                <h4>+91 953 807 6717</h4>
                <a href="mailto:santhoshakutumbam@gmail.com" className="button">SEND MAIL</a>
            </div>
        </div>
        <div className="footer-bottom">
            <h5>Website is Designed & Developed By <span><a href="https://sachinjha.tech" target='_blank'>Sachin Jha</a></span> | Copyright © 2023 HAPPY FAMILY</h5>
        </div>
    </div>
  )
}
