import React from 'react'
import "../css/componentCss/EventListCard.css"
import eventImg from "../images/eventImg.png"
export default function EventListCard(props) {
  return (
    <div className='event-list-card'>
      <img src={props.thumbnail} alt={props.thumbnail} />
      <div className="event-summary">
        <h5>{props.eventName}</h5>
        <p>{props.eventSummary}</p>
        <h6>{props.eventDate}</h6>
      </div>
    </div>
  )
}
