import React from 'react'
import "../css/componentCss/TestimonialSection.css"
import TestimonialCard from './TestimonialCard'
export default function TestimonialSection() {
  const testimonial = [
    {
      id:1,
      name:"Sachin Jha",
      feedback:"Happy Family changed my life by providing shelter and education. I'm forever grateful for their unwavering support"
    },
    {
      id:2,
      name:"Vikas Jha",
      feedback:"Their dedication to helping the less fortunate is awe-inspiring. I've seen lives transformed by Happy Family's work."
    },
    {
      id:3,
      name:"Manisha Jha",
      feedback:"Happy Family's commitment to making the world better is truly commendable. They've given hope to many."
    },
    {
      id:4,
      name:"Satyam Jha",
      feedback:"I'm proud to support Happy Family. Their tireless efforts in providing food and shelter have made a lasting impact."
    },

    {
      id:5,
      name:"Ritu Jha",
      feedback:"Happy Family's impact on our community is immeasurable. They're a beacon of hope for those in need."
    },
  ]
  return (
    <div className='testimonial-section'>
        <h3>Our <span>Family</span> Member</h3>
        <p>Welcome to our Testimonial Section, where the heartwarming stories of those who've experienced the impact of Happy Family's work come to life. Hear from individuals whose lives have been transformed by compassion and support.</p>
        <p>"VIEW OUR TESTIMONIALS"</p>
        <div className="testimonial-scroll-section">
            {testimonial.map((elem) => (
              <TestimonialCard name={elem.name} feedback={elem.feedback}/>
            ))}
        </div>
    </div>
  )
}
