import React, { useState } from 'react'
import "../../css/adminComponents/UserQueryCard.css"
import { useDispatch } from 'react-redux';
export default function UserQueryCard(props) {
  const [deleteScreen, setDeleteScreen] = useState(false);
  const showDeleteScreen = () => {
    setDeleteScreen(true)
  }
  const hideDeleteScreen = () => {
    setDeleteScreen(false)
  }

  const deletehandle = (id) => {
    props.handleDelete(id)
    hideDeleteScreen()
  }

  return (
    <>
      <div className='user-query-card'>
        <div className="user-query-card-left">
          <h4>{props.name}</h4>
          <h6>{props.email}</h6>
        </div>
        <div className="user-query-card-right">
          <p>{props.querydetails}</p>
        </div>
        <button className='btn-a' onClick={showDeleteScreen}>Delete Query</button>
      </div>
      {deleteScreen && <div className="delete-screen">
        <div className="delete-msg-box">
          <h5>Do you want to delete ?</h5>
          <div className="common-flex-section">
            <div className="btn-a confirm-btn" onClick={deletehandle(props._id)}>Confirm</div>
            <div className="btn-a cancel-btn" onClick={hideDeleteScreen}>Cancel</div>
          </div>
        </div>
      </div>}
    </>
  )
}
