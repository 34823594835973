import React, { useState } from 'react'
import "../../css/adminComponents/UpcomingEventHorizontal.css"
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import tryImg from "../../images/eventImg.png"
import CloseIcon from '@mui/icons-material/Close';
import { editEvents, fetchEvents } from '../../redux/action/Actions';
export default function UpcomingEventHorizontal(props) {
  const [deleteScreen, setDeleteScreen] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  let listevents = useSelector((state) => state.upcomingEvents.events);
  const [events, setEvents] = useState(
    listevents
  );
  const dispatch = useDispatch();
  const [editedEventData, setEditedEventData] = useState({
    eventName: '',
    eventDate: '',
    googleFormLink: '',
    thumbnail: '',
    detailedDescription: '',
    eventSummary: '',
  });

  const showDeleteScreen = () => {
    setDeleteScreen(true)
  }
  const hideDeleteScreen = () => {
    setDeleteScreen(false)
  }

  const deletehandle = (id) => {
    props.handleDelete(id)
    hideDeleteScreen()
  }


  const handleEditClick = (event) => {
    setEditedEventData({ ...event });
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {



    dispatch(editEvents(editedEventData));

    setIsEditing(false);
    setEditedEventData({
      id: null,
      eventName: '',
      eventDate: '',
      googleFormLink: '',
      thumbnail: '',
      detailedDescription: '',
      eventSummary: '',
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedEventData({
      id: null,
      eventName: '',
      eventDate: '',
      googleFormLink: '',
      thumbnail: '',
      detailedDescription: '',
      eventSummary: '',
    });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedEventData({
      ...editedEventData,
      [name]: value,
    });
  };

  return (
    <>
      <div className='upcoming-event-horizontal'>
        <img src={props.thumbnail} alt={props.thumbnail} />
        <h5>{props.eventName}</h5>
        <h5 className='event-date-h5'>{props.eventDate}</h5>
        <div className="edit-delete-box">
          <BorderColorIcon className='edit-icon' onClick={() => handleEditClick(props)} />
          <DeleteIcon className='delete-icon' onClick={showDeleteScreen} />
        </div>
      </div>
      {deleteScreen && <div className="delete-screen">
        <div className="delete-msg-box">
          <h5>Do you want to delete ?</h5>
          <div className="common-flex-section">
            <div className="btn-a confirm-btn" onClick={() => deletehandle(props._id)}>Confirm</div>
            <div className="btn-a cancel-btn" onClick={hideDeleteScreen}>Cancel</div>
          </div>
        </div>
      </div>}

      {isEditing && (
        <div className="create-event-screen" style={{ zIndex: 5 }}>
          <form className="create-event-form" onSubmit={handleSaveEdit} action="/upload" >
            <CloseIcon className='close-icon' onClick={handleCancelEdit} />
            <div className="row">

              <div className="name-and-date-box">
                <input
                  type="text"
                  name="eventName"
                  placeholder='Enter Event Name'
                  className='input-field'
                  value={editedEventData.eventName}
                  onChange={handleEditInputChange}
                />
                <input
                  type="date"
                  name="eventDate"
                  placeholder='dd/mm/yyyy'
                  className='input-field'
                  value={editedEventData.eventDate}
                  onChange={handleEditInputChange}
                />
                <input
                  type="text"
                  name="googleFormLink"
                  className="input-field"
                  placeholder='Enter Google Form Link'
                  value={editedEventData.googleFormLink}
                  onChange={handleEditInputChange}
                />
              </div>
            </div>
            <div className="row">
              <textarea
                name="detailedDescription"
                placeholder='Enter Detailed Description (100 words)'
                value={editedEventData.detailedDescription}
                onChange={handleEditInputChange}
              ></textarea>
              <textarea
                name="eventSummary"
                className='summary'
                placeholder='Enter Event Summary (20-30 words)'
                value={editedEventData.eventSummary}
                onChange={handleEditInputChange}
              ></textarea>
            </div>
            <input type="submit" value="Save Changes" className='download-btn' />
            <button onClick={handleCancelEdit} className='download-btn'>Cancel</button>
          </form>
        </div>
      )}
    </>
  )
}
