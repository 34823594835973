// store.js

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { combineReducers } from 'redux';
import authReducer from './reducer/authReducer';
import eventReducer from './reducer/upcomingeventReducer';

const rootReducer = combineReducers({
    upcomingEvents: eventReducer,
    auth: authReducer,
});

const store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware)
);

export default store;
