import React from 'react'
import "../css/componentCss/GoalsAchievedSection.css"
import goalsAchievedImage from "../images/goals achieved section img.png"
export default function GoalsAchievedSection() {
  return (
    <div className='goals-achieved-section common-flex-section'>
        <div className="right-side">
            <h3>We love to help all the children that have problems in the world. After 5 years we have many goals achieved.</h3>
            <div className="common-underline"></div>
            <p>Over our journey, Happy Family has achieved remarkable milestones in serving our mission to provide shelter, education, and nourishment to those in need. We've successfully provided shelter to hundreds of families, educated countless children, and served thousands of meals to the hungry. These tangible outcomes are a testament to our commitment to creating a happier world.</p>
            <p>But our achievements go beyond numbers. We've witnessed lives transformed, hope rekindled, and communities strengthened. Each success story is a source of inspiration, fueling our dedication to continue making a positive impact in the lives of those less fortunate. Our journey is far from over, and with your support, we're excited to achieve even more in the future.</p>
        </div>
        <div className="left-side">
            <img src={goalsAchievedImage} alt="" />
        </div>
    </div>
  )
}
