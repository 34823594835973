import React from 'react'
import "../css/componentCss/AboutUsSection.css"
import aboutUsImage from "../images/about us section img.png"
import hangingHeart from "../images/hanging heart.png"
export default function AboutUsSection() {
// let colors = ["#38B6FF" , "#36AB9C" , "#683998" , "#ED0E64" , "#F6AC0D" , "#F5F5F6"];
// function moment(e){
//     let moveX = e.clientX;
//     let moveY = e.clientY;
//     let circle = document.createElement("span");
//     let randomNum = Math.floor(Math.random()*colors.length);
//     let circleColor = colors[randomNum];
//     let size = Math.random()*35;
//     circle.style.width = size + "px";
//     circle.style.height = size*0.4 + "px";
//     circle.style.backgroundColor = circleColor;
//     circle.style.top = moveY+"px";
//     circle.style.left = moveX+"px"
//     e.target.appendChild(circle)
//     setTimeout(()=>{
//         circle.remove();
//     },500)
// }
  return (
    <div className='about-us-section common-flex-section' >
            <div className="right-side">
                <img src={aboutUsImage} alt="" />
            </div>
            <div className="left-side">
                <h3>We Are</h3>
                <h1>HAPPY FAMILY</h1>
                <p>At Happy Family, we are driven by the belief that kindness and compassion can transform lives. Founded on the principles of love and empathy, our organization is dedicated to creating a brighter future for those in need. We provide more than just food, shelter, and education; we provide hope and opportunities for individuals and families to rebuild their lives. Our unwavering commitment to nurturing the underprivileged extends to empowering women through job training and skill development, fostering self-reliance and independence.</p>
                <p>But our mission goes beyond material support. We believe in the power of community and awareness. Through countless events and initiatives, we have rallied together people from all walks of life, inspiring them to lend a helping hand and champion the cause of the less fortunate.</p>
            </div>
            <img src={hangingHeart} alt="" className="hanging-heart" />
            <img src={hangingHeart} alt="" className="hanging-heart-2" />
            <div className="overlay"></div>
    </div>
  )
}
