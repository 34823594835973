import React, { useEffect } from 'react'
import "../css/pageCss/LandingPage.css";
import logo from "../images/logo.png"
import CommonCard from '../components/CommonCard';
import GoalsAchievedSection from '../components/GoalsAchievedSection';
import AboutUsSection from '../components/AboutUsSection';
import ContributeSection from '../components/ContributeSection';
import UpcomingEventsSection from '../components/UpcomingEventsSection';
import TestimonialSection from '../components/TestimonialSection';
import Navbar from './Navbar';
import volunteer from "../images/volunteer-common-card.png"
import donate from "../images/donate-common-card.png"
import event from "../images/event-common-card.png"
import SocialMedia from '../components/SocialMedia';
import { Helmet } from 'react-helmet';

export default function LandingPage() {
// let colors = ["#38B6FF" , "#36AB9C" , "#683998" , "#ED0E64" , "#F6AC0D" , "#F5F5F6"];
// function moment(e){
//     let moveX = e.clientX;
//     let moveY = e.clientY;
//     let circle = document.createElement("span");
//     let randomNum = Math.floor(Math.random()*colors.length);
//     let circleColor = colors[randomNum];
//     let size = Math.random()*35;
//     circle.style.width = size + "px";
//     circle.style.height = size*0.4 + "px";
//     circle.style.backgroundColor = circleColor;
//     circle.style.top = moveY+"px";
//     circle.style.left = moveX+"px"
//     e.target.appendChild(circle)
//     setTimeout(()=>{
//         circle.remove();
//     },500)
// }

    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    return (
        <>
        <Helmet>
        <title>Happy Family | Home Page</title>
        <meta name="description" content="Welcome to Happy Family - Providing Food, Shelter, and Education to Needy People" />
        <meta name="keywords" content="Happy Family, social welfare, charity, food, shelter, education, events, helping others" />
      </Helmet>
            <div className='home-page-landing-section' id='main'>
                <img src={logo} alt="" className='home-page-landing-section-logo' />
                <h3>Welcome To</h3>
                <h1>HAPPY FAMILY</h1>
                <p>A dream to provide everything to everyone with love. We are the platform to serve the required thing to those who are not able or not blessed as us by the God.</p>
                <p>Bet the part of our journey and be the reason for someone’s smile!!</p>
                <div className="drop-animation"></div>
            </div>
            <div className="common-flex-section">
                <CommonCard image = {volunteer} title = "Volunteer" description="At Happy Family, we believe that volunteers are the heart and soul of our organization. Your time, skills, and dedication can make a profound impact on the lives of those in need. Join our team of compassionate volunteers and be a part of creating a brighter, happier world for those less fortunate."/>
                <CommonCard image = {donate} title = "Donate To US" description="Donate to Us Your support empowers us to continue providing shelter, education, and nourishment to those in need. Every contribution, no matter the size, makes a difference. Help us create a happier world together. Give today."/>
                <CommonCard image = {event} title = "Events" description="Join us in our upcoming events! From fundraisers to community outreach, your participation can make a significant impact. Stay connected to Happy Family's mission and be a part of something special. Save the date and be part of positive change."/>
            </div>
            <div className="home-page-quality-section common-flex-section">
                <div className="quality-element">
                    <img src={logo} alt="" />
                    <h3>10K+</h3>
                    <h5>Children Helped</h5>
                </div>
                <div className="quality-element">
                    <img src={logo} alt="" />
                    <h3>1K+</h3>
                    <h5>Women Helped</h5>
                </div>
                <div className="quality-element">
                    <img src={logo} alt="" />
                    <h3>100+</h3>
                    <h5>Volunteer Members</h5>
                </div>
            </div>
            <GoalsAchievedSection/>
            <AboutUsSection/>
            <ContributeSection/>
            <SocialMedia/>
            <UpcomingEventsSection/>
            <TestimonialSection/>
        </>
    )
}
