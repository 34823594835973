import React, { useEffect } from 'react'
import "../../css/adminPages/AdminPreviousEventPage.css"
import PreviousEventMainCard from '../adminComponentJsx/PreviousEventMainCard'
import tryImage from "../../images/eventImg.png"
import { useDispatch, useSelector } from 'react-redux'
import { deleteEvent, fetchEvents, fetchPrevEvents } from '../../redux/action/Actions'

export default function AdminPreviousEventPage() {
  const preevevents = useSelector((state) => state.upcomingEvents.prevEvents);
  const dispatch = useDispatch();


  const handleDelete = (id) => {
    dispatch(deleteEvent(id))
    dispatch(fetchPrevEvents());
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(fetchPrevEvents());
  }, []);
  return (
    <div className='previous-event-page'>
      <div className="previous-event-page-header">
        <h1>Manage <br />Previous Events</h1>
      </div>
      <div className="previous-event-page-events-section">
        {
          preevevents.length > 0 && preevevents.map((item) => {
            return <PreviousEventMainCard key={item.id} {...item} handleDelete={handleDelete} />
          })
        }
      </div>
    </div>
  )
}
