import React , {useState , useEffect} from 'react'
// import "../css/componentCss/ContributeSection.css"
// import qrCode from "../images/qr-code.png"
// export default function ContributeSection() {
//   return (
//     <div className='contribute-section'>
//         <h2>YOUR CONTRIBUTION</h2>
//         <p>Transform your kindness into action! Simply scan our QR code and become a part of our journey to bring happiness and support to those who need it most. Your generosity matters.</p>
//         <p>Scan to Share the Love! Use our QR code to make a difference with your donation today. Every contribution brings smiles to those in need.</p>
//         <img src={qrCode} alt="" />
//     </div>
//   )
// }

import "../css/componentCss/ContributeSection.css"
import Axios from '../api';
export default function ContributeSection() {

  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  useEffect(() => {
    async function fetchQrCodeUrl() {
      try {
        const response = await Axios.get('/api/links');
        if (response.status != 200) {
          throw new Error('Network response was not ok');
        }
        const data = await response.data
        console.log(data);
        setQrCodeUrl(data.url);
      } catch (error) {
        console.error('Error fetching QR code URL:', error);
      }
    }

    fetchQrCodeUrl();
  }, []);

  return (
    <div className='contribute-section'>
      <h2>YOUR CONTRIBUTION</h2>
      <p>Transform your kindness into action! Simply scan our QR code and become a part of our journey to bring happiness and support to those who need it most. Your generosity matters.</p>
      <p>Scan to Share the Love! Use our QR code to make a difference with your donation today. Every contribution brings smiles to those in need.</p>
      {qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" />}
    </div>
  )
}