import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const CheckAuth = ({ children }) => {
  const isAuthenticated = localStorage.getItem('admin_Token');
  console.log(isAuthenticated)
  if (!isAuthenticated) {
    return <Navigate to="/login-register" replace={true} />;
  }


  return children;
};

export default CheckAuth;
