import React, { useState } from 'react'
import "../css/pageCss/Navbar.css";
import logo from "../images/logo.png"
import { Link } from 'react-router-dom';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Logout } from '../redux/action/Actions';
export default function Navbar() {
  // window.addEventListener('scroll', () => {
  //     if(window.scrollY >= 150){
  //         document.querySelector('.navbar').style.backgroundColor = "var(--yellow)";
  //     }
  //     else if(window.scrollY < 50){
  //         document.querySelector('.navbar').style.backgroundColor = "transparent";
  //     }
  // })
  const [login, setLogin] = useState(false);
  const isUser = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const [displayResponsiveMenu, setDisplayResponsiveMenu] = useState(false)

  const displayMenu = () => {
    setDisplayResponsiveMenu(true)
  }
  const closeMenu = () => {
    setDisplayResponsiveMenu(false)
  }
  const [deleteScreen, setDeleteScreen] = useState(false)
  const showDeleteScreen = () => {
    setDeleteScreen(true)
  }
  const hideDeleteScreen = () => {
    setDeleteScreen(false)
  }

  const handleLogout = () => {
    dispatch(Logout())
    hideDeleteScreen();
    window.location.reload()
  }
  return (
    <>
      <div className='navbar'>
      <span className='row'>
        <img src={logo} alt="" />
        <h4>Happy Family</h4>
      </span>
        <div className="nav-items">
          <Link to="/">Home</Link>
          <Link to="/events">Events</Link>
          <Link to="/volunteer-guideline">Volunteer Guidelines</Link>
          <Link to="/contact-us">Contact Us</Link>
          {!isUser && <Link to="/login-register" className='btn-a'>Login</Link>}
          {isUser && <span className='btn-a logout-btn' onClick={showDeleteScreen}>Logout</span>}
        </div>
        <WidgetsIcon className='menu-icon' onClick={displayMenu} />

        {displayResponsiveMenu && <div className="responsive-navbar">
          <CloseIcon className='close-icon' onClick={closeMenu} />
          <Link to="/" className='item' onClick={closeMenu}>Home</Link>
          <Link to="/events" className='item' onClick={closeMenu}>Events</Link>
          <Link to="/volunteer-guideline" className='item' onClick={closeMenu}>Volunteer Guidelines</Link>
          <Link to="/contact-us" className='item' onClick={closeMenu}>Contact Us</Link>
          {!isUser && <Link to="/login-register" className='btn-a'>Login</Link>}
          {isUser && <span className='btn-a logout-btn' onClick={showDeleteScreen}>Logout</span>}
        </div>}
      </div>
      {deleteScreen && <div className="delete-screen">
        <div className="delete-msg-box">
          <h5>Want To LOGOUT ?</h5>
          <div className="common-flex-section">
            <div className="btn-a confirm-btn" onClick={handleLogout}>Confirm</div>
            <div className="btn-a cancel-btn" onClick={hideDeleteScreen}>Cancel</div>
          </div>
        </div>
      </div>}
    </>
  )
}
