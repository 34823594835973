import React, { useEffect } from 'react'
import "../css/pageCss/EventPage.css"
import UpcomingEventsSection from '../components/UpcomingEventsSection';
import PartnerSection from '../components/PartnerSection';
import PreviousEventSection from '../components/PreviousEventSection';
import whyEvent1 from "../images/event icon 1.png"
import whyEvent2 from "../images/event icon 2.png"
import whyEvent3 from "../images/event icon 3.png"
import whyEvent4 from "../images/event icon 4.png"
import { Helmet } from 'react-helmet';


export default function EventPage() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
     <Helmet>
        <title>Happy Family | Events</title>
        <meta name="description" content="Join Our Events - a social welfare organization that offers food, shelter, education, and organizes events to encourage people to help others." />
        <meta name="keywords" content="Happy Famil events, upcoming events, previous events" />
      </Helmet>
        <div className="event-page-landing-section">
            <h1>Everyone has the right of education</h1>
            <p>Happy Family is a dedicated non-profit organization committed to improving the lives of the less fortunate. We provide shelter, education, food, and support to those in need, with a mission to create a brighter, happier world. </p>
        </div> 




        <div className="event-page-why-join-section">
          <div className="event-page-why-join-section-left">
            <div className="event-page-why-join-section-left-icon">
              <img src={whyEvent1} alt="" />
              <h5>Make Real Impact</h5>
            </div>
            <div className="event-page-why-join-section-left-icon">
              <img src={whyEvent2} alt="" />
              <h5>Spread Happiness</h5>
            </div>
            <div className="event-page-why-join-section-left-icon">
              <img src={whyEvent3} alt="" />
              <h5>Personal Growth</h5>
            </div>
            <div className="event-page-why-join-section-left-icon">
              <img src={whyEvent4} alt="" />
              <h5>Be a Changemaker</h5>
            </div>
          </div>
          <div className="event-page-why-join-section-right">
            <h2>Why you should join our events ?</h2>
            <p>Joining Happy Family means you'll be at the forefront of creating positive change in the lives of those less fortunate. Your efforts will directly contribute to providing shelter, education, and nourishment to those in need.</p>
            <p>We offer a variety of volunteer roles to suit your interests and availability. Whether it's participating in events, mentoring, or contributing your professional skills, there's a place for you here.</p>
          </div>
        </div>



        <UpcomingEventsSection/>

        <PartnerSection/>

        <PreviousEventSection/>
    </>
  )
}
